.Sidebar {
  background-color: white;
  height: 100%;
  width: 264px;
  transition: all 0.2s linear;
}

.Sidebar.Minify {
  width: 52px;
}

.ToggleButtonSection {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0;
}

.ToggleButtonSection button {
  background-color: transparent;
  border: 1px solid white;
  border-radius: 5px;
  width: 0;
  height: 0;
  color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  outline: none;
  cursor: pointer;
}

.Sidebar ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.Sidebar ul li {
  width: 100%;
  position: relative;
}
.Sidebar ul li > ul{
  padding-right: 0;
  padding-bottom: 0;
  display: none;
}
.Sidebar ul li > ul.Open{
  display: block;
  background: rgba(243, 243, 243, 0.74);
}
.Sidebar ul a {
  font-size: 16px;
  font-weight: 600;
  color: #7b7b7b;
  outline: none;
  cursor: pointer;
  text-decoration: none;
}
.Sidebar ul li > ul li{
  margin-bottom: 0;
  line-height: 48px;
}
.Sidebar ul li a{
  line-height: 48px;
  padding-left: 54px;
  display: block;
}

.Sidebar ul button {
  color: #7b7b7b;
  font-size: 16px;
  line-height: 46px;
  font-weight: 600;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  background: none;
  padding: 0 0 0 54px;
  border: 0;
  position: relative;
}
.Sidebar ul a > span, .Sidebar ul button > span {
  margin-left: 5px;
  animation-name: fadeIn;
  animation-duration: 0.6s;
}
.Sidebar ul button.Current{
  background: rgba(243, 243, 243, 0.74);
}
.Sidebar ul a.Current span{
  color: #fff !important;
}
.Sidebar ul a.Current:before, .Sidebar ul button.Current:before{
  content: "";
  display: block;
  position: absolute;
  top: 1px;
  left: -24px;
  width: 4px;
  height: 16px;
  border-radius: 0 2px 2px 0;
  background-color: #00aa4f;
}
.Sidebar ul li a.Current, .Sidebar ul li > ul button.Current{
  background-color: #00aa4f;
}
.Sidebar ul li > ul a.Current:before, .Sidebar ul li > ul button.Current:before{
  display: none;
}
.Sidebar ul a.Current > span, .Sidebar ul button.Current > span{
  color: #202020;
}
.Sidebar ul button{
  position: relative;
  width: 100%;
  text-align: left;
}
.Sidebar ul button:after{
  content: "";
  position: absolute;
  display: block;
  width: 6px;
  height: 10px;
  right: 27px;
  top: 20px;
  background-color: #bebebe;
  -webkit-mask-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2IiBoZWlnaHQ9IjEwIiB2aWV3Qm94PSIwIDAgNiAxMCI+PGc+PGc+PHBhdGggZmlsbD0iI2JlYmViZSIgZD0iTTMuNTY4IDVMLjI5NSAxLjcyMmExLjAxIDEuMDEgMCAwIDEgMC0xLjQyNyAxLjAwNiAxLjAwNiAwIDAgMSAxLjQyNCAwbDMuOTg2IDMuOTkyYTEuMDEgMS4wMSAwIDAgMSAwIDEuNDI2TDEuNzE5IDkuNzA1YTEuMDA2IDEuMDA2IDAgMCAxLTEuNDI0IDAgMS4wMSAxLjAxIDAgMCAxIDAtMS40Mjd6Ii8+PC9nPjwvZz48L3N2Zz4=");
  mask-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2IiBoZWlnaHQ9IjEwIiB2aWV3Qm94PSIwIDAgNiAxMCI+PGc+PGc+PHBhdGggZmlsbD0iI2JlYmViZSIgZD0iTTMuNTY4IDVMLjI5NSAxLjcyMmExLjAxIDEuMDEgMCAwIDEgMC0xLjQyNyAxLjAwNiAxLjAwNiAwIDAgMSAxLjQyNCAwbDMuOTg2IDMuOTkyYTEuMDEgMS4wMSAwIDAgMSAwIDEuNDI2TDEuNzE5IDkuNzA1YTEuMDA2IDEuMDA2IDAgMCAxLTEuNDI0IDAgMS4wMSAxLjAxIDAgMCAxIDAtMS40Mjd6Ii8+PC9nPjwvZz48L3N2Zz4=");
  transform: rotate(90deg);
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.Sidebar ul button.Current:after{
  transform: rotate(-90deg);
}
.Sidebar.Minify ul a > span {
  animation-name: fadeOut;
  animation-duration: 0.6s;
}

@keyframes fadeIn {
  from {opacity: 0}
  to {opacity: 1}
}

@keyframes fadeOut {
  from {opacity: 1}
  to {opacity: 0}
}
.IconItem{
  position: absolute;
  left: 24px;
  top: 16px;
  width: 16px;
  height: 16px;
  display: inline-block;
  background-color: #7b7b7b;
}
.Sidebar ul .Current > .IconItem, .Sidebar ul li:hover .IconItem{
  background-color: #00aa4f;
}
.Sidebar ul a.Current > .IconItem{
  background-color: white;
}
.status{
  mask-image:  url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij48Zz48ZyBjbGlwLXBhdGg9InVybCgjY2xpcC0xNTQ3RDY3MC1CMzIxLTQ1RjEtQTZERC1FNzhDRjdCM0NGODYpIj48cGF0aCBmaWxsPSIjN2I3YjdiIiBkPSJNNC43MjcgNy4yOTdsMi4yOCAyLjI4MSA0LjI2NS00LjI2NGExIDEgMCAxIDEgMS40MTQgMS40MTRsLTQuOTA1IDQuOTA2YTEuMDEzIDEuMDEzIDAgMCAxLS40MzIuMzAzIDEgMSAwIDAgMS0xLjE0NS0uMzM0TDMuMzEyIDguNzEyYTEgMSAwIDAgMSAxLjQxNS0xLjQxNXpNOCAxNkE4IDggMCAxIDAgOCAwYTggOCAwIDAgMCAwIDE2eiIvPjwvZz48L2c+PC9zdmc+")
}
.IconItem.users {
  left: 26px;
  width: 12px;
  mask-image:  url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDEyIDE2Ij48Zz48ZyBjbGlwLXBhdGg9InVybCgjY2xpcC00MTI1MDk1QS00NjJFLTRBNkEtODc1MC02NTBDREM3OTY5RjYpIj48cGF0aCBmaWxsPSIjMDBhYTRmIiBkPSJNNiA4QzMuNzkyIDggMiA2LjIwNyAyIDQuMDAyQTQuMDAyIDQuMDAyIDAgMCAxIDYgMGMyLjIwOCAwIDQgMS43OTYgNCA0LjAwMkE0LjAwMiA0LjAwMiAwIDAgMSA2IDh6bTAgMmMyLjIxMSAwIDQuMjcxIDEuMTk1IDUuMzc2IDMuMTE5bC40MzMuNzU0Yy4yNTYuNDQ2LjI1My45NzYgMCAxLjQxOWExLjM5IDEuMzkgMCAwIDEtMS4yMi43MDhIMS40MTJjLS41MDkgMC0uOTY2LS4yNjUtMS4yMjEtLjcwOGExLjQxIDEuNDEgMCAwIDEgMC0xLjQxOWwuNDMzLS43NTRBNi4yMTggNi4yMTggMCAwIDEgNiAxMHoiLz48L2c+PC9nPjwvc3ZnPg==")
}
.barChains {
  mask-image:  url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij48Zz48ZyBjbGlwLXBhdGg9InVybCgjY2xpcC1CRUMxQUI4Mi01QTVBLTQ0QUQtODY3OC0wODQ0RDNBRTRBRjgpIj48cGF0aCBmaWxsPSIjN2I3YjdiIiBkPSJNMCAxYTEgMSAwIDAgMSAxLTFoNWExIDEgMCAwIDEgMSAxdjVhMSAxIDAgMCAxLTEgMUgxYTEgMSAwIDAgMS0xLTF6bTkgMGExIDEgMCAwIDEgMS0xaDVhMSAxIDAgMCAxIDEgMXY1YTEgMSAwIDAgMS0xIDFoLTVhMSAxIDAgMCAxLTEtMXptMCA5YTEgMSAwIDAgMSAxLTFoNWExIDEgMCAwIDEgMSAxdjVhMSAxIDAgMCAxLTEgMWgtNWExIDEgMCAwIDEtMS0xem0tOSAwYTEgMSAwIDAgMSAxLTFoNWExIDEgMCAwIDEgMSAxdjVhMSAxIDAgMCAxLTEgMUgxYTEgMSAwIDAgMS0xLTF6Ii8+PC9nPjwvZz48L3N2Zz4=")
}
.bars {
  mask-image:  url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij48Zz48Zz48cGF0aCBmaWxsPSIjN2I3YjdiIiBkPSJNLjQyOCA2LjUwNUEzLjAxNyAzLjAxNyAwIDAgMSAwIDVhMSAxIDAgMCAxIC4wNC0uMjhsMS4xNjctNGExIDEgMCAwIDEgLjk2LS43MmgxMS42NjZhMSAxIDAgMCAxIC45Ni43MmwxLjE2NyA0QTEgMSAwIDAgMSAxNiA1YzAgLjQxMi0uMTA1Ljk1LS40MjggMS41MDVhMi44ODYgMi44ODYgMCAwIDEtLjU3Mi43MDNjLS41NjYuNTA1LTEuMzUuNzkzLTIuMzMzLjc5My0uOTg0IDAtMS43NjgtLjI4OC0yLjMzNC0uNzkzQzkuNzY3IDcuNzEzIDguOTgzIDggOCA4Yy0uOTgzIDAtMS43NjctLjI4OC0yLjMzMy0uNzkzQzUuMSA3LjcxMyA0LjMxNyA4IDMuMzMzIDggMi4zNSA4IDEuNTY2IDcuNzEzIDEgNy4yMDhhMi44ODYgMi44ODYgMCAwIDEtLjU3Mi0uNzAzek0xNSAxNGEyIDIgMCAwIDEtMiAyaC0ydi0yLjVhMS41IDEuNSAwIDEgMC0zIDBWMTZIM2EyIDIgMCAwIDEtMi0yVjkuNTQ1Yy42OTcuMyAxLjQ4NS40NTYgMi4zMzMuNDU2Ljg0NCAwIDEuNjMyLS4xNTMgMi4zMzQtLjQ1NUM2LjM2OSA5Ljg0OCA3LjE1NiAxMCA4IDEwYy44NDQgMCAxLjYzMS0uMTUzIDIuMzMzLS40NTUuNzAyLjMwMiAxLjQ5LjQ1NSAyLjMzNC40NTUuODQ4IDAgMS42MzYtLjE1NiAyLjMzMy0uNDU2eiIvPjwvZz48L2c+PC9zdmc+")
}
.equipment {
  mask-image:  url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij48Zz48Zz48cGF0aCBmaWxsPSIjN2I3YjdiIiBkPSJNNiA4YTIgMiAwIDEgMSA0IDAgMiAyIDAgMCAxLTQgMHptLS40OS02LjAxMWwtLjY5OS0uN2ExIDEgMCAwIDAtMS40MTQgMEwuNTgyIDQuMTA1IDEuOTkgNS41MUgxYTEgMSAwIDAgMC0xIDF2My45OGgxLjk4OWwtLjcuNjk5YTEgMSAwIDAgMCAwIDEuNDE0bDIuODE1IDIuODE1TDUuNTEgMTQuMDFWMTVhMSAxIDAgMCAwIDEgMWgzLjk4di0xLjk4OWwuNjk5LjdhMSAxIDAgMCAwIDEuNDE0IDBsMi4xMDctMi4xMDhhMSAxIDAgMCAwIDAtMS40MTRsLS42OTktLjY5OUgxNWExIDEgMCAwIDAgMS0xVjYuNTFhMSAxIDAgMCAwLTEtMWgtLjk4OWwuNy0uNjk5YTEgMSAwIDAgMCAwLTEuNDE0TDEyLjYwMiAxLjI5YTEgMSAwIDAgMC0xLjQxNCAwbC0uNjk5LjY5OVYxYTEgMSAwIDAgMC0xLTFINi41MWExIDEgMCAwIDAtMSAxeiIvPjwvZz48L2c+PC9zdmc+")
}
.menu {
  width: 14px;
  left: 25px;
  mask-image:  url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE0IDE2Ij48Zz48Zz48cGF0aCBmaWxsPSIjN2I3YjdiIiBkPSJNMi4yOSAwaDkuNDJsMS43MSA0YzEuNzcgNC4xMzktLjY0IDcuNTQ0LTUuNDIgNy45NThWMTRoMmExIDEgMCAwIDEgMCAySDRhMSAxIDAgMCAxIDAtMmgydi0yLjA0MkMxLjIyIDExLjU0NC0xLjE5IDguMTM4LjU4IDR6bTEuMzIgMkwyLjQyIDQuNzg2Yy0uMDMxLjA3Mi0uMDYuMTQzLS4wODcuMjE0aDkuMzM0YTYuMTU4IDYuMTU4IDAgMCAwLS4wODYtLjIxNEwxMC4zODkgMnoiLz48L2c+PC9nPjwvc3ZnPg==")
}
.IconItem.orders {
  width: 16px;
  mask-image:  url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij48Zz48Zz48cGF0aCBmaWxsPSIjN2I3YjdiIiBkPSJNOC44ODQgMTAuNTYxTDcuODcyIDkuNTVhMSAxIDAgMCAwLTEuNDE1IDEuNDE0bDEuNzIgMS43MmEuOTk3Ljk5NyAwIDAgMCAxLjQxNCAwbDMuOTUyLTMuOTUxYTEgMSAwIDAgMC0xLjQxNS0xLjQxNXpNNCA2YTIgMiAwIDAgMSAyLTJoOGEyIDIgMCAwIDEgMiAydjhhMiAyIDAgMCAxLTIgMkg2YTIgMiAwIDAgMS0yLTJ6TTAgMmEyIDIgMCAwIDEgMi0yaDhhMiAyIDAgMCAxIDIgMkg0YTIgMiAwIDAgMC0yIDJ2OGEyIDIgMCAwIDEtMi0yeiIvPjwvZz48L2c+PC9zdmc+")
}
.statistics {
  mask-image:  url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij48Zz48Zz48cGF0aCBmaWxsPSIjN2I3YjdiIiBkPSJNMSAxMWExIDEgMCAwIDAgMiAwVjRhMSAxIDAgMCAwLTIgMHptMTIgMGExIDEgMCAwIDAgMiAwVjNhMSAxIDAgMCAwLTIgMHptLTQgMGExIDEgMCAwIDAgMiAwVjFhMSAxIDAgMCAwLTIgMHptLTQgMGExIDEgMCAwIDAgMiAwVjZhMSAxIDAgMCAwLTIgMHptLTUgNGExIDEgMCAwIDAgMSAxaDE0YTEgMSAwIDAgMCAwLTJIMWExIDEgMCAwIDAtMSAxeiIvPjwvZz48L2c+PC9zdmc+")
}