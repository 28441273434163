body, html{
  overflow-x: hidden;
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Source Sans Pro', sans-serif;
}
body a{
  color: #00aa4f;
  font-size: 14px;
  font-weight: 400;
}
body a:hover{
  text-decoration: underline;
}
body a:active{
  color: #008d42;
}
.Layout {
    padding: 0;
}
.Layout main{
    display: flex;
    width: 100vw;
    min-height: 100vh;
    overflow: hidden;
}
.Layout .LeftSide, .Layout .RightSide{
    width: 50%;
}
.Layout .RightSide{
    display: flex;
    /*align-items: center;*/
    justify-content: center;
    padding-top: 143px;
}
.Layout .LeftSide{
    background-size: cover;
}
.RightSideInner header a{
    width: 203px !important;
}
.SpinnerToMiddle {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}
.CopyrightLine {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
}
.CopyrightLine a {
    color: #404040 !important;
    line-height: 27px !important;
    font-size: 16px !important;
    letter-spacing: .5px !important;
    font-family: 'Droid Sans', sans-serif !important;
    text-decoration: none !important;
}
.CopyrightLine a:hover, .CopyrightLine a:active {
    text-decoration: underline !important;
}
.TopSection {
  background-color: #870025;
  color: white;
  padding: 10px 0;
  font-size: 13px;
}

.MiddleSection, .BottomSection {

}

.MiddleSection .loggedWrap {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 68px;
  box-shadow: 0 1px 5px rgba(193, 193, 193, 0.31);
}
.MiddleSection a.LogoLink{
  display: block;
  width: 152px;
  margin: 0 auto;
}
.MiddleSection .Logo {
  width: 100%;
}
.MiddleSection .alignLeft{
  margin-left: 20px;
}
.MiddleSection .alignLeft a.LogoLink{
  width: 175px;
}
.BottomSection .content {
  display: flex;
  align-items: center;
  height: 69px;
}
.MiddleSection .alignright {
  margin-right: 20px;
  display: flex;
}
.MiddleSection .alignright .Notifications {
  cursor: pointer;
  margin: 8px 13px 0 0;
  background-color: #bebebe;
  width: 20px;
  height: 24px;
  -webkit-mask-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDIwIDI0Ij48Zz48Zz48cGF0aCBmaWxsPSIjYmViZWJlIiBkPSJNNiAyMWMuMDA3IDEuNjU1IDEuNzk0IDIuOTk1IDQgMyAyLjIwNi0uMDA1IDMuOTkzLTEuMzQ1IDQtM3ptLTQtNWgtLjVhMS41IDEuNSAwIDEgMCAwIDNoMTdhMS41IDEuNSAwIDEgMCAwLTNIMThjLTEuMS0xLjAzNi0xLjkwMS0yLjQxNy0yLTRWN2MuMDE3LTEuODE0LS4zODgtMy43NC0xLjU2My01LjAyMkMxMy4yNi42OTUgMTEuNjYyLS4wMTggMTAgMCA4LjMzOC0uMDE3IDYuNzM5LjY5NSA1LjU2NCAxLjk3OCA0LjM4OCAzLjI2MSAzLjk4NCA1LjE4NiA0IDd2NS4xNzhjLS4xIDEuNTgzLS45IDIuNzg2LTIgMy44MjJ6Ii8+PC9nPjwvZz48L3N2Zz4=");
  mask-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDIwIDI0Ij48Zz48Zz48cGF0aCBmaWxsPSIjYmViZWJlIiBkPSJNNiAyMWMuMDA3IDEuNjU1IDEuNzk0IDIuOTk1IDQgMyAyLjIwNi0uMDA1IDMuOTkzLTEuMzQ1IDQtM3ptLTQtNWgtLjVhMS41IDEuNSAwIDEgMCAwIDNoMTdhMS41IDEuNSAwIDEgMCAwLTNIMThjLTEuMS0xLjAzNi0xLjkwMS0yLjQxNy0yLTRWN2MuMDE3LTEuODE0LS4zODgtMy43NC0xLjU2My01LjAyMkMxMy4yNi42OTUgMTEuNjYyLS4wMTggMTAgMCA4LjMzOC0uMDE3IDYuNzM5LjY5NSA1LjU2NCAxLjk3OCA0LjM4OCAzLjI2MSAzLjk4NCA1LjE4NiA0IDd2NS4xNzhjLS4xIDEuNTgzLS45IDIuNzg2LTIgMy44MjJ6Ii8+PC9nPjwvZz48L3N2Zz4=");
  transition: all 0.3s ease-out;
}
.MiddleSection .alignright .Notifications:hover{
  background-color: #8a8887;
}
.MiddleSection .alignright > div > button{
  background: transparent;
  border: none;
}
.MiddleSection .alignright > div button{
  padding-top: 0;
  padding-bottom: 0;
}
.MiddleSection .alignright > div > button+div{
  right: 0 !important;
  left: auto !important;
}
.MiddleSection .alignright > div > button+div > button{
  padding: 0;
}
.MiddleSection .alignright > div > button+div > button a{
  display: block;
  padding: 0.5px 1.5rem;
}
.alignright p{
  text-decoration: none;
  color: #7a7a7a;
  font-size: 16px;
  font-weight: 400;
  transition: all 0.3s ease-out;
}
.Profile .Role{
  opacity: 0.5;
  color: #8d8d8d;
  font-size: 11px;
  font-weight: 700;
  line-height: 11px;
}
.alignright .Profile{
  display: flex;
  cursor: pointer;
  position: relative;
  padding-right: 28px;
}
.Profile:after {
  content: "";
  display: block;
  position: absolute;
  width: 6px;
  height: 10px;
  right: 0;
  top: 16px;
  background-color: #bebebe;
  -webkit-mask-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2IiBoZWlnaHQ9IjEwIiB2aWV3Qm94PSIwIDAgNiAxMCI+PGc+PGc+PHBhdGggZmlsbD0iI2JlYmViZSIgZD0iTTMuNTY4IDVMLjI5NSAxLjcyMmExLjAxIDEuMDEgMCAwIDEgMC0xLjQyNyAxLjAwNiAxLjAwNiAwIDAgMSAxLjQyNCAwbDMuOTg2IDMuOTkyYTEuMDEgMS4wMSAwIDAgMSAwIDEuNDI2TDEuNzE5IDkuNzA1YTEuMDA2IDEuMDA2IDAgMCAxLTEuNDI0IDAgMS4wMSAxLjAxIDAgMCAxIDAtMS40Mjd6Ii8+PC9nPjwvZz48L3N2Zz4=");
  mask-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2IiBoZWlnaHQ9IjEwIiB2aWV3Qm94PSIwIDAgNiAxMCI+PGc+PGc+PHBhdGggZmlsbD0iI2JlYmViZSIgZD0iTTMuNTY4IDVMLjI5NSAxLjcyMmExLjAxIDEuMDEgMCAwIDEgMC0xLjQyNyAxLjAwNiAxLjAwNiAwIDAgMSAxLjQyNCAwbDMuOTg2IDMuOTkyYTEuMDEgMS4wMSAwIDAgMSAwIDEuNDI2TDEuNzE5IDkuNzA1YTEuMDA2IDEuMDA2IDAgMCAxLTEuNDI0IDAgMS4wMSAxLjAxIDAgMCAxIDAtMS40Mjd6Ii8+PC9nPjwvZz48L3N2Zz4=");
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  transition: all 0.3s ease-out;
}
.Profile.Open:after{
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}
.Profile .Avatar{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
}
.Profile .Avatar img{
  max-width: 100%;
}
.Profile .Description{
  text-align: left;
  padding-left: 10px;
}
.alignright a:hover{
  opacity: 0.7;
}
.Hide {
  font-size: 0;
  display: none;
}

@media (max-width: 1170px) {
  .content {
    padding: 0 15px;
    width: 100%;
    box-sizing: border-box;
  }
}
@media (max-width: 1000px) {
  .subWrapper .subTitle {
    font-size: 28px;
  }
}
@media (max-width: 900px) {
  .subWrapper .subTitle {
    font-size: 24px;
  }
}
@media (max-width: 860px) {
  .MiddleSection .content .loggedWrap {
    display: block;
    text-align: center;
  }
}

@media (max-width: 700px) {
  .MiddleSection a.LogoLink{
    width: 44%;
  }
}
.AdminLayout {
  width: 100vw;
  min-height: 100vh;
  background-size: cover;
}

.AdminLayout > main {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.ALContent {
  flex: 1 1;
  display: flex;
}

.AdminLayout > main article {
  padding: 24px 23px;
  background: #ececec;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  box-sizing: border-box;
}

.Sidebar {
  background-color: white;
  height: 100%;
  width: 264px;
  transition: all 0.2s linear;
}

.Sidebar.Minify {
  width: 52px;
}

.ToggleButtonSection {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0;
}

.ToggleButtonSection button {
  background-color: transparent;
  border: 1px solid white;
  border-radius: 5px;
  width: 0;
  height: 0;
  color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  outline: none;
  cursor: pointer;
}

.Sidebar ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.Sidebar ul li {
  width: 100%;
  position: relative;
}
.Sidebar ul li > ul{
  padding-right: 0;
  padding-bottom: 0;
  display: none;
}
.Sidebar ul li > ul.Open{
  display: block;
  background: rgba(243, 243, 243, 0.74);
}
.Sidebar ul a {
  font-size: 16px;
  font-weight: 600;
  color: #7b7b7b;
  outline: none;
  cursor: pointer;
  text-decoration: none;
}
.Sidebar ul li > ul li{
  margin-bottom: 0;
  line-height: 48px;
}
.Sidebar ul li a{
  line-height: 48px;
  padding-left: 54px;
  display: block;
}

.Sidebar ul button {
  color: #7b7b7b;
  font-size: 16px;
  line-height: 46px;
  font-weight: 600;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  background: none;
  padding: 0 0 0 54px;
  border: 0;
  position: relative;
}
.Sidebar ul a > span, .Sidebar ul button > span {
  margin-left: 5px;
  -webkit-animation-name: fadeIn;
          animation-name: fadeIn;
  -webkit-animation-duration: 0.6s;
          animation-duration: 0.6s;
}
.Sidebar ul button.Current{
  background: rgba(243, 243, 243, 0.74);
}
.Sidebar ul a.Current span{
  color: #fff !important;
}
.Sidebar ul a.Current:before, .Sidebar ul button.Current:before{
  content: "";
  display: block;
  position: absolute;
  top: 1px;
  left: -24px;
  width: 4px;
  height: 16px;
  border-radius: 0 2px 2px 0;
  background-color: #00aa4f;
}
.Sidebar ul li a.Current, .Sidebar ul li > ul button.Current{
  background-color: #00aa4f;
}
.Sidebar ul li > ul a.Current:before, .Sidebar ul li > ul button.Current:before{
  display: none;
}
.Sidebar ul a.Current > span, .Sidebar ul button.Current > span{
  color: #202020;
}
.Sidebar ul button{
  position: relative;
  width: 100%;
  text-align: left;
}
.Sidebar ul button:after{
  content: "";
  position: absolute;
  display: block;
  width: 6px;
  height: 10px;
  right: 27px;
  top: 20px;
  background-color: #bebebe;
  -webkit-mask-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2IiBoZWlnaHQ9IjEwIiB2aWV3Qm94PSIwIDAgNiAxMCI+PGc+PGc+PHBhdGggZmlsbD0iI2JlYmViZSIgZD0iTTMuNTY4IDVMLjI5NSAxLjcyMmExLjAxIDEuMDEgMCAwIDEgMC0xLjQyNyAxLjAwNiAxLjAwNiAwIDAgMSAxLjQyNCAwbDMuOTg2IDMuOTkyYTEuMDEgMS4wMSAwIDAgMSAwIDEuNDI2TDEuNzE5IDkuNzA1YTEuMDA2IDEuMDA2IDAgMCAxLTEuNDI0IDAgMS4wMSAxLjAxIDAgMCAxIDAtMS40Mjd6Ii8+PC9nPjwvZz48L3N2Zz4=");
  mask-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2IiBoZWlnaHQ9IjEwIiB2aWV3Qm94PSIwIDAgNiAxMCI+PGc+PGc+PHBhdGggZmlsbD0iI2JlYmViZSIgZD0iTTMuNTY4IDVMLjI5NSAxLjcyMmExLjAxIDEuMDEgMCAwIDEgMC0xLjQyNyAxLjAwNiAxLjAwNiAwIDAgMSAxLjQyNCAwbDMuOTg2IDMuOTkyYTEuMDEgMS4wMSAwIDAgMSAwIDEuNDI2TDEuNzE5IDkuNzA1YTEuMDA2IDEuMDA2IDAgMCAxLTEuNDI0IDAgMS4wMSAxLjAxIDAgMCAxIDAtMS40Mjd6Ii8+PC9nPjwvZz48L3N2Zz4=");
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  transition: all 0.3s ease-out;
}
.Sidebar ul button.Current:after{
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}
.Sidebar.Minify ul a > span {
  -webkit-animation-name: fadeOut;
          animation-name: fadeOut;
  -webkit-animation-duration: 0.6s;
          animation-duration: 0.6s;
}

@-webkit-keyframes fadeIn {
  from {opacity: 0}
  to {opacity: 1}
}

@keyframes fadeIn {
  from {opacity: 0}
  to {opacity: 1}
}

@-webkit-keyframes fadeOut {
  from {opacity: 1}
  to {opacity: 0}
}

@keyframes fadeOut {
  from {opacity: 1}
  to {opacity: 0}
}
.IconItem{
  position: absolute;
  left: 24px;
  top: 16px;
  width: 16px;
  height: 16px;
  display: inline-block;
  background-color: #7b7b7b;
}
.Sidebar ul .Current > .IconItem, .Sidebar ul li:hover .IconItem{
  background-color: #00aa4f;
}
.Sidebar ul a.Current > .IconItem{
  background-color: white;
}
.status{
  -webkit-mask-image:  url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij48Zz48ZyBjbGlwLXBhdGg9InVybCgjY2xpcC0xNTQ3RDY3MC1CMzIxLTQ1RjEtQTZERC1FNzhDRjdCM0NGODYpIj48cGF0aCBmaWxsPSIjN2I3YjdiIiBkPSJNNC43MjcgNy4yOTdsMi4yOCAyLjI4MSA0LjI2NS00LjI2NGExIDEgMCAxIDEgMS40MTQgMS40MTRsLTQuOTA1IDQuOTA2YTEuMDEzIDEuMDEzIDAgMCAxLS40MzIuMzAzIDEgMSAwIDAgMS0xLjE0NS0uMzM0TDMuMzEyIDguNzEyYTEgMSAwIDAgMSAxLjQxNS0xLjQxNXpNOCAxNkE4IDggMCAxIDAgOCAwYTggOCAwIDAgMCAwIDE2eiIvPjwvZz48L2c+PC9zdmc+");
          mask-image:  url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij48Zz48ZyBjbGlwLXBhdGg9InVybCgjY2xpcC0xNTQ3RDY3MC1CMzIxLTQ1RjEtQTZERC1FNzhDRjdCM0NGODYpIj48cGF0aCBmaWxsPSIjN2I3YjdiIiBkPSJNNC43MjcgNy4yOTdsMi4yOCAyLjI4MSA0LjI2NS00LjI2NGExIDEgMCAxIDEgMS40MTQgMS40MTRsLTQuOTA1IDQuOTA2YTEuMDEzIDEuMDEzIDAgMCAxLS40MzIuMzAzIDEgMSAwIDAgMS0xLjE0NS0uMzM0TDMuMzEyIDguNzEyYTEgMSAwIDAgMSAxLjQxNS0xLjQxNXpNOCAxNkE4IDggMCAxIDAgOCAwYTggOCAwIDAgMCAwIDE2eiIvPjwvZz48L2c+PC9zdmc+")
}
.IconItem.users {
  left: 26px;
  width: 12px;
  -webkit-mask-image:  url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDEyIDE2Ij48Zz48ZyBjbGlwLXBhdGg9InVybCgjY2xpcC00MTI1MDk1QS00NjJFLTRBNkEtODc1MC02NTBDREM3OTY5RjYpIj48cGF0aCBmaWxsPSIjMDBhYTRmIiBkPSJNNiA4QzMuNzkyIDggMiA2LjIwNyAyIDQuMDAyQTQuMDAyIDQuMDAyIDAgMCAxIDYgMGMyLjIwOCAwIDQgMS43OTYgNCA0LjAwMkE0LjAwMiA0LjAwMiAwIDAgMSA2IDh6bTAgMmMyLjIxMSAwIDQuMjcxIDEuMTk1IDUuMzc2IDMuMTE5bC40MzMuNzU0Yy4yNTYuNDQ2LjI1My45NzYgMCAxLjQxOWExLjM5IDEuMzkgMCAwIDEtMS4yMi43MDhIMS40MTJjLS41MDkgMC0uOTY2LS4yNjUtMS4yMjEtLjcwOGExLjQxIDEuNDEgMCAwIDEgMC0xLjQxOWwuNDMzLS43NTRBNi4yMTggNi4yMTggMCAwIDEgNiAxMHoiLz48L2c+PC9nPjwvc3ZnPg==");
          mask-image:  url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDEyIDE2Ij48Zz48ZyBjbGlwLXBhdGg9InVybCgjY2xpcC00MTI1MDk1QS00NjJFLTRBNkEtODc1MC02NTBDREM3OTY5RjYpIj48cGF0aCBmaWxsPSIjMDBhYTRmIiBkPSJNNiA4QzMuNzkyIDggMiA2LjIwNyAyIDQuMDAyQTQuMDAyIDQuMDAyIDAgMCAxIDYgMGMyLjIwOCAwIDQgMS43OTYgNCA0LjAwMkE0LjAwMiA0LjAwMiAwIDAgMSA2IDh6bTAgMmMyLjIxMSAwIDQuMjcxIDEuMTk1IDUuMzc2IDMuMTE5bC40MzMuNzU0Yy4yNTYuNDQ2LjI1My45NzYgMCAxLjQxOWExLjM5IDEuMzkgMCAwIDEtMS4yMi43MDhIMS40MTJjLS41MDkgMC0uOTY2LS4yNjUtMS4yMjEtLjcwOGExLjQxIDEuNDEgMCAwIDEgMC0xLjQxOWwuNDMzLS43NTRBNi4yMTggNi4yMTggMCAwIDEgNiAxMHoiLz48L2c+PC9nPjwvc3ZnPg==")
}
.barChains {
  -webkit-mask-image:  url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij48Zz48ZyBjbGlwLXBhdGg9InVybCgjY2xpcC1CRUMxQUI4Mi01QTVBLTQ0QUQtODY3OC0wODQ0RDNBRTRBRjgpIj48cGF0aCBmaWxsPSIjN2I3YjdiIiBkPSJNMCAxYTEgMSAwIDAgMSAxLTFoNWExIDEgMCAwIDEgMSAxdjVhMSAxIDAgMCAxLTEgMUgxYTEgMSAwIDAgMS0xLTF6bTkgMGExIDEgMCAwIDEgMS0xaDVhMSAxIDAgMCAxIDEgMXY1YTEgMSAwIDAgMS0xIDFoLTVhMSAxIDAgMCAxLTEtMXptMCA5YTEgMSAwIDAgMSAxLTFoNWExIDEgMCAwIDEgMSAxdjVhMSAxIDAgMCAxLTEgMWgtNWExIDEgMCAwIDEtMS0xem0tOSAwYTEgMSAwIDAgMSAxLTFoNWExIDEgMCAwIDEgMSAxdjVhMSAxIDAgMCAxLTEgMUgxYTEgMSAwIDAgMS0xLTF6Ii8+PC9nPjwvZz48L3N2Zz4=");
          mask-image:  url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij48Zz48ZyBjbGlwLXBhdGg9InVybCgjY2xpcC1CRUMxQUI4Mi01QTVBLTQ0QUQtODY3OC0wODQ0RDNBRTRBRjgpIj48cGF0aCBmaWxsPSIjN2I3YjdiIiBkPSJNMCAxYTEgMSAwIDAgMSAxLTFoNWExIDEgMCAwIDEgMSAxdjVhMSAxIDAgMCAxLTEgMUgxYTEgMSAwIDAgMS0xLTF6bTkgMGExIDEgMCAwIDEgMS0xaDVhMSAxIDAgMCAxIDEgMXY1YTEgMSAwIDAgMS0xIDFoLTVhMSAxIDAgMCAxLTEtMXptMCA5YTEgMSAwIDAgMSAxLTFoNWExIDEgMCAwIDEgMSAxdjVhMSAxIDAgMCAxLTEgMWgtNWExIDEgMCAwIDEtMS0xem0tOSAwYTEgMSAwIDAgMSAxLTFoNWExIDEgMCAwIDEgMSAxdjVhMSAxIDAgMCAxLTEgMUgxYTEgMSAwIDAgMS0xLTF6Ii8+PC9nPjwvZz48L3N2Zz4=")
}
.bars {
  -webkit-mask-image:  url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij48Zz48Zz48cGF0aCBmaWxsPSIjN2I3YjdiIiBkPSJNLjQyOCA2LjUwNUEzLjAxNyAzLjAxNyAwIDAgMSAwIDVhMSAxIDAgMCAxIC4wNC0uMjhsMS4xNjctNGExIDEgMCAwIDEgLjk2LS43MmgxMS42NjZhMSAxIDAgMCAxIC45Ni43MmwxLjE2NyA0QTEgMSAwIDAgMSAxNiA1YzAgLjQxMi0uMTA1Ljk1LS40MjggMS41MDVhMi44ODYgMi44ODYgMCAwIDEtLjU3Mi43MDNjLS41NjYuNTA1LTEuMzUuNzkzLTIuMzMzLjc5My0uOTg0IDAtMS43NjgtLjI4OC0yLjMzNC0uNzkzQzkuNzY3IDcuNzEzIDguOTgzIDggOCA4Yy0uOTgzIDAtMS43NjctLjI4OC0yLjMzMy0uNzkzQzUuMSA3LjcxMyA0LjMxNyA4IDMuMzMzIDggMi4zNSA4IDEuNTY2IDcuNzEzIDEgNy4yMDhhMi44ODYgMi44ODYgMCAwIDEtLjU3Mi0uNzAzek0xNSAxNGEyIDIgMCAwIDEtMiAyaC0ydi0yLjVhMS41IDEuNSAwIDEgMC0zIDBWMTZIM2EyIDIgMCAwIDEtMi0yVjkuNTQ1Yy42OTcuMyAxLjQ4NS40NTYgMi4zMzMuNDU2Ljg0NCAwIDEuNjMyLS4xNTMgMi4zMzQtLjQ1NUM2LjM2OSA5Ljg0OCA3LjE1NiAxMCA4IDEwYy44NDQgMCAxLjYzMS0uMTUzIDIuMzMzLS40NTUuNzAyLjMwMiAxLjQ5LjQ1NSAyLjMzNC40NTUuODQ4IDAgMS42MzYtLjE1NiAyLjMzMy0uNDU2eiIvPjwvZz48L2c+PC9zdmc+");
          mask-image:  url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij48Zz48Zz48cGF0aCBmaWxsPSIjN2I3YjdiIiBkPSJNLjQyOCA2LjUwNUEzLjAxNyAzLjAxNyAwIDAgMSAwIDVhMSAxIDAgMCAxIC4wNC0uMjhsMS4xNjctNGExIDEgMCAwIDEgLjk2LS43MmgxMS42NjZhMSAxIDAgMCAxIC45Ni43MmwxLjE2NyA0QTEgMSAwIDAgMSAxNiA1YzAgLjQxMi0uMTA1Ljk1LS40MjggMS41MDVhMi44ODYgMi44ODYgMCAwIDEtLjU3Mi43MDNjLS41NjYuNTA1LTEuMzUuNzkzLTIuMzMzLjc5My0uOTg0IDAtMS43NjgtLjI4OC0yLjMzNC0uNzkzQzkuNzY3IDcuNzEzIDguOTgzIDggOCA4Yy0uOTgzIDAtMS43NjctLjI4OC0yLjMzMy0uNzkzQzUuMSA3LjcxMyA0LjMxNyA4IDMuMzMzIDggMi4zNSA4IDEuNTY2IDcuNzEzIDEgNy4yMDhhMi44ODYgMi44ODYgMCAwIDEtLjU3Mi0uNzAzek0xNSAxNGEyIDIgMCAwIDEtMiAyaC0ydi0yLjVhMS41IDEuNSAwIDEgMC0zIDBWMTZIM2EyIDIgMCAwIDEtMi0yVjkuNTQ1Yy42OTcuMyAxLjQ4NS40NTYgMi4zMzMuNDU2Ljg0NCAwIDEuNjMyLS4xNTMgMi4zMzQtLjQ1NUM2LjM2OSA5Ljg0OCA3LjE1NiAxMCA4IDEwYy44NDQgMCAxLjYzMS0uMTUzIDIuMzMzLS40NTUuNzAyLjMwMiAxLjQ5LjQ1NSAyLjMzNC40NTUuODQ4IDAgMS42MzYtLjE1NiAyLjMzMy0uNDU2eiIvPjwvZz48L2c+PC9zdmc+")
}
.equipment {
  -webkit-mask-image:  url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij48Zz48Zz48cGF0aCBmaWxsPSIjN2I3YjdiIiBkPSJNNiA4YTIgMiAwIDEgMSA0IDAgMiAyIDAgMCAxLTQgMHptLS40OS02LjAxMWwtLjY5OS0uN2ExIDEgMCAwIDAtMS40MTQgMEwuNTgyIDQuMTA1IDEuOTkgNS41MUgxYTEgMSAwIDAgMC0xIDF2My45OGgxLjk4OWwtLjcuNjk5YTEgMSAwIDAgMCAwIDEuNDE0bDIuODE1IDIuODE1TDUuNTEgMTQuMDFWMTVhMSAxIDAgMCAwIDEgMWgzLjk4di0xLjk4OWwuNjk5LjdhMSAxIDAgMCAwIDEuNDE0IDBsMi4xMDctMi4xMDhhMSAxIDAgMCAwIDAtMS40MTRsLS42OTktLjY5OUgxNWExIDEgMCAwIDAgMS0xVjYuNTFhMSAxIDAgMCAwLTEtMWgtLjk4OWwuNy0uNjk5YTEgMSAwIDAgMCAwLTEuNDE0TDEyLjYwMiAxLjI5YTEgMSAwIDAgMC0xLjQxNCAwbC0uNjk5LjY5OVYxYTEgMSAwIDAgMC0xLTFINi41MWExIDEgMCAwIDAtMSAxeiIvPjwvZz48L2c+PC9zdmc+");
          mask-image:  url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij48Zz48Zz48cGF0aCBmaWxsPSIjN2I3YjdiIiBkPSJNNiA4YTIgMiAwIDEgMSA0IDAgMiAyIDAgMCAxLTQgMHptLS40OS02LjAxMWwtLjY5OS0uN2ExIDEgMCAwIDAtMS40MTQgMEwuNTgyIDQuMTA1IDEuOTkgNS41MUgxYTEgMSAwIDAgMC0xIDF2My45OGgxLjk4OWwtLjcuNjk5YTEgMSAwIDAgMCAwIDEuNDE0bDIuODE1IDIuODE1TDUuNTEgMTQuMDFWMTVhMSAxIDAgMCAwIDEgMWgzLjk4di0xLjk4OWwuNjk5LjdhMSAxIDAgMCAwIDEuNDE0IDBsMi4xMDctMi4xMDhhMSAxIDAgMCAwIDAtMS40MTRsLS42OTktLjY5OUgxNWExIDEgMCAwIDAgMS0xVjYuNTFhMSAxIDAgMCAwLTEtMWgtLjk4OWwuNy0uNjk5YTEgMSAwIDAgMCAwLTEuNDE0TDEyLjYwMiAxLjI5YTEgMSAwIDAgMC0xLjQxNCAwbC0uNjk5LjY5OVYxYTEgMSAwIDAgMC0xLTFINi41MWExIDEgMCAwIDAtMSAxeiIvPjwvZz48L2c+PC9zdmc+")
}
.menu {
  width: 14px;
  left: 25px;
  -webkit-mask-image:  url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE0IDE2Ij48Zz48Zz48cGF0aCBmaWxsPSIjN2I3YjdiIiBkPSJNMi4yOSAwaDkuNDJsMS43MSA0YzEuNzcgNC4xMzktLjY0IDcuNTQ0LTUuNDIgNy45NThWMTRoMmExIDEgMCAwIDEgMCAySDRhMSAxIDAgMCAxIDAtMmgydi0yLjA0MkMxLjIyIDExLjU0NC0xLjE5IDguMTM4LjU4IDR6bTEuMzIgMkwyLjQyIDQuNzg2Yy0uMDMxLjA3Mi0uMDYuMTQzLS4wODcuMjE0aDkuMzM0YTYuMTU4IDYuMTU4IDAgMCAwLS4wODYtLjIxNEwxMC4zODkgMnoiLz48L2c+PC9nPjwvc3ZnPg==");
          mask-image:  url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE0IDE2Ij48Zz48Zz48cGF0aCBmaWxsPSIjN2I3YjdiIiBkPSJNMi4yOSAwaDkuNDJsMS43MSA0YzEuNzcgNC4xMzktLjY0IDcuNTQ0LTUuNDIgNy45NThWMTRoMmExIDEgMCAwIDEgMCAySDRhMSAxIDAgMCAxIDAtMmgydi0yLjA0MkMxLjIyIDExLjU0NC0xLjE5IDguMTM4LjU4IDR6bTEuMzIgMkwyLjQyIDQuNzg2Yy0uMDMxLjA3Mi0uMDYuMTQzLS4wODcuMjE0aDkuMzM0YTYuMTU4IDYuMTU4IDAgMCAwLS4wODYtLjIxNEwxMC4zODkgMnoiLz48L2c+PC9nPjwvc3ZnPg==")
}
.IconItem.orders {
  width: 16px;
  -webkit-mask-image:  url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij48Zz48Zz48cGF0aCBmaWxsPSIjN2I3YjdiIiBkPSJNOC44ODQgMTAuNTYxTDcuODcyIDkuNTVhMSAxIDAgMCAwLTEuNDE1IDEuNDE0bDEuNzIgMS43MmEuOTk3Ljk5NyAwIDAgMCAxLjQxNCAwbDMuOTUyLTMuOTUxYTEgMSAwIDAgMC0xLjQxNS0xLjQxNXpNNCA2YTIgMiAwIDAgMSAyLTJoOGEyIDIgMCAwIDEgMiAydjhhMiAyIDAgMCAxLTIgMkg2YTIgMiAwIDAgMS0yLTJ6TTAgMmEyIDIgMCAwIDEgMi0yaDhhMiAyIDAgMCAxIDIgMkg0YTIgMiAwIDAgMC0yIDJ2OGEyIDIgMCAwIDEtMi0yeiIvPjwvZz48L2c+PC9zdmc+");
          mask-image:  url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij48Zz48Zz48cGF0aCBmaWxsPSIjN2I3YjdiIiBkPSJNOC44ODQgMTAuNTYxTDcuODcyIDkuNTVhMSAxIDAgMCAwLTEuNDE1IDEuNDE0bDEuNzIgMS43MmEuOTk3Ljk5NyAwIDAgMCAxLjQxNCAwbDMuOTUyLTMuOTUxYTEgMSAwIDAgMC0xLjQxNS0xLjQxNXpNNCA2YTIgMiAwIDAgMSAyLTJoOGEyIDIgMCAwIDEgMiAydjhhMiAyIDAgMCAxLTIgMkg2YTIgMiAwIDAgMS0yLTJ6TTAgMmEyIDIgMCAwIDEgMi0yaDhhMiAyIDAgMCAxIDIgMkg0YTIgMiAwIDAgMC0yIDJ2OGEyIDIgMCAwIDEtMi0yeiIvPjwvZz48L2c+PC9zdmc+")
}
.statistics {
  -webkit-mask-image:  url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij48Zz48Zz48cGF0aCBmaWxsPSIjN2I3YjdiIiBkPSJNMSAxMWExIDEgMCAwIDAgMiAwVjRhMSAxIDAgMCAwLTIgMHptMTIgMGExIDEgMCAwIDAgMiAwVjNhMSAxIDAgMCAwLTIgMHptLTQgMGExIDEgMCAwIDAgMiAwVjFhMSAxIDAgMCAwLTIgMHptLTQgMGExIDEgMCAwIDAgMiAwVjZhMSAxIDAgMCAwLTIgMHptLTUgNGExIDEgMCAwIDAgMSAxaDE0YTEgMSAwIDAgMCAwLTJIMWExIDEgMCAwIDAtMSAxeiIvPjwvZz48L2c+PC9zdmc+");
          mask-image:  url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij48Zz48Zz48cGF0aCBmaWxsPSIjN2I3YjdiIiBkPSJNMSAxMWExIDEgMCAwIDAgMiAwVjRhMSAxIDAgMCAwLTIgMHptMTIgMGExIDEgMCAwIDAgMiAwVjNhMSAxIDAgMCAwLTIgMHptLTQgMGExIDEgMCAwIDAgMiAwVjFhMSAxIDAgMCAwLTIgMHptLTQgMGExIDEgMCAwIDAgMiAwVjZhMSAxIDAgMCAwLTIgMHptLTUgNGExIDEgMCAwIDAgMSAxaDE0YTEgMSAwIDAgMCAwLTJIMWExIDEgMCAwIDAtMSAxeiIvPjwvZz48L2c+PC9zdmc+")
}
.ContentLayout {

}
.ContentLayout .Content {
  padding: 30px;
}
*:focus {
    outline: none;
}
html, body{
    height: 100%;
}
.ContentPage h2 {
    font-size: 20px;
    margin-bottom: 20px;
}
.ContentPage p {
    margin-bottom: 20px;
}
