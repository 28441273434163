.TopSection {
  background-color: #870025;
  color: white;
  padding: 10px 0;
  font-size: 13px;
}

.MiddleSection, .BottomSection {

}

.MiddleSection .loggedWrap {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 68px;
  box-shadow: 0 1px 5px rgba(193, 193, 193, 0.31);
}
.MiddleSection a.LogoLink{
  display: block;
  width: 152px;
  margin: 0 auto;
}
.MiddleSection .Logo {
  width: 100%;
}
.MiddleSection .alignLeft{
  margin-left: 20px;
}
.MiddleSection .alignLeft a.LogoLink{
  width: 175px;
}
.BottomSection .content {
  display: flex;
  align-items: center;
  height: 69px;
}
.MiddleSection .alignright {
  margin-right: 20px;
  display: flex;
}
.MiddleSection .alignright .Notifications {
  cursor: pointer;
  margin: 8px 13px 0 0;
  background-color: #bebebe;
  width: 20px;
  height: 24px;
  -webkit-mask-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDIwIDI0Ij48Zz48Zz48cGF0aCBmaWxsPSIjYmViZWJlIiBkPSJNNiAyMWMuMDA3IDEuNjU1IDEuNzk0IDIuOTk1IDQgMyAyLjIwNi0uMDA1IDMuOTkzLTEuMzQ1IDQtM3ptLTQtNWgtLjVhMS41IDEuNSAwIDEgMCAwIDNoMTdhMS41IDEuNSAwIDEgMCAwLTNIMThjLTEuMS0xLjAzNi0xLjkwMS0yLjQxNy0yLTRWN2MuMDE3LTEuODE0LS4zODgtMy43NC0xLjU2My01LjAyMkMxMy4yNi42OTUgMTEuNjYyLS4wMTggMTAgMCA4LjMzOC0uMDE3IDYuNzM5LjY5NSA1LjU2NCAxLjk3OCA0LjM4OCAzLjI2MSAzLjk4NCA1LjE4NiA0IDd2NS4xNzhjLS4xIDEuNTgzLS45IDIuNzg2LTIgMy44MjJ6Ii8+PC9nPjwvZz48L3N2Zz4=");
  mask-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDIwIDI0Ij48Zz48Zz48cGF0aCBmaWxsPSIjYmViZWJlIiBkPSJNNiAyMWMuMDA3IDEuNjU1IDEuNzk0IDIuOTk1IDQgMyAyLjIwNi0uMDA1IDMuOTkzLTEuMzQ1IDQtM3ptLTQtNWgtLjVhMS41IDEuNSAwIDEgMCAwIDNoMTdhMS41IDEuNSAwIDEgMCAwLTNIMThjLTEuMS0xLjAzNi0xLjkwMS0yLjQxNy0yLTRWN2MuMDE3LTEuODE0LS4zODgtMy43NC0xLjU2My01LjAyMkMxMy4yNi42OTUgMTEuNjYyLS4wMTggMTAgMCA4LjMzOC0uMDE3IDYuNzM5LjY5NSA1LjU2NCAxLjk3OCA0LjM4OCAzLjI2MSAzLjk4NCA1LjE4NiA0IDd2NS4xNzhjLS4xIDEuNTgzLS45IDIuNzg2LTIgMy44MjJ6Ii8+PC9nPjwvZz48L3N2Zz4=");
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.MiddleSection .alignright .Notifications:hover{
  background-color: #8a8887;
}
.MiddleSection .alignright > div > button{
  background: transparent;
  border: none;
}
.MiddleSection .alignright > div button{
  padding-top: 0;
  padding-bottom: 0;
}
.MiddleSection .alignright > div > button+div{
  right: 0 !important;
  left: auto !important;
}
.MiddleSection .alignright > div > button+div > button{
  padding: 0;
}
.MiddleSection .alignright > div > button+div > button a{
  display: block;
  padding: 0.5px 1.5rem;
}
.alignright p{
  text-decoration: none;
  color: #7a7a7a;
  font-size: 16px;
  font-weight: 400;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.Profile .Role{
  opacity: 0.5;
  color: #8d8d8d;
  font-size: 11px;
  font-weight: 700;
  line-height: 11px;
}
.alignright .Profile{
  display: flex;
  cursor: pointer;
  position: relative;
  padding-right: 28px;
}
.Profile:after {
  content: "";
  display: block;
  position: absolute;
  width: 6px;
  height: 10px;
  right: 0;
  top: 16px;
  background-color: #bebebe;
  -webkit-mask-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2IiBoZWlnaHQ9IjEwIiB2aWV3Qm94PSIwIDAgNiAxMCI+PGc+PGc+PHBhdGggZmlsbD0iI2JlYmViZSIgZD0iTTMuNTY4IDVMLjI5NSAxLjcyMmExLjAxIDEuMDEgMCAwIDEgMC0xLjQyNyAxLjAwNiAxLjAwNiAwIDAgMSAxLjQyNCAwbDMuOTg2IDMuOTkyYTEuMDEgMS4wMSAwIDAgMSAwIDEuNDI2TDEuNzE5IDkuNzA1YTEuMDA2IDEuMDA2IDAgMCAxLTEuNDI0IDAgMS4wMSAxLjAxIDAgMCAxIDAtMS40Mjd6Ii8+PC9nPjwvZz48L3N2Zz4=");
  mask-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2IiBoZWlnaHQ9IjEwIiB2aWV3Qm94PSIwIDAgNiAxMCI+PGc+PGc+PHBhdGggZmlsbD0iI2JlYmViZSIgZD0iTTMuNTY4IDVMLjI5NSAxLjcyMmExLjAxIDEuMDEgMCAwIDEgMC0xLjQyNyAxLjAwNiAxLjAwNiAwIDAgMSAxLjQyNCAwbDMuOTg2IDMuOTkyYTEuMDEgMS4wMSAwIDAgMSAwIDEuNDI2TDEuNzE5IDkuNzA1YTEuMDA2IDEuMDA2IDAgMCAxLTEuNDI0IDAgMS4wMSAxLjAxIDAgMCAxIDAtMS40Mjd6Ii8+PC9nPjwvZz48L3N2Zz4=");
  transform: rotate(90deg);
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.Profile.Open:after{
  transform: rotate(-90deg);
}
.Profile .Avatar{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
}
.Profile .Avatar img{
  max-width: 100%;
}
.Profile .Description{
  text-align: left;
  padding-left: 10px;
}
.alignright a:hover{
  opacity: 0.7;
}
.Hide {
  font-size: 0;
  display: none;
}

@media (max-width: 1170px) {
  .content {
    padding: 0 15px;
    width: 100%;
    box-sizing: border-box;
  }
}
@media (max-width: 1000px) {
  .subWrapper .subTitle {
    font-size: 28px;
  }
}
@media (max-width: 900px) {
  .subWrapper .subTitle {
    font-size: 24px;
  }
}
@media (max-width: 860px) {
  .MiddleSection .content .loggedWrap {
    display: block;
    text-align: center;
  }
}

@media (max-width: 700px) {
  .MiddleSection a.LogoLink{
    width: 44%;
  }
}