.AdminLayout {
  width: 100vw;
  min-height: 100vh;
  background-size: cover;
}

.AdminLayout > main {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.ALContent {
  flex: 1;
  display: flex;
}

.AdminLayout > main article {
  padding: 24px 23px;
  background: #ececec;
  display: flex;
  flex-direction: column;
  flex: 1;
  box-sizing: border-box;
}
