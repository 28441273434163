.Layout {
    padding: 0;
}
.Layout main{
    display: flex;
    width: 100vw;
    min-height: 100vh;
    overflow: hidden;
}
.Layout .LeftSide, .Layout .RightSide{
    width: 50%;
}
.Layout .RightSide{
    display: flex;
    /*align-items: center;*/
    justify-content: center;
    padding-top: 143px;
}
.Layout .LeftSide{
    background-size: cover;
}
.RightSideInner header a{
    width: 203px !important;
}
.SpinnerToMiddle {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}
.CopyrightLine {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
}
.CopyrightLine a {
    color: #404040 !important;
    line-height: 27px !important;
    font-size: 16px !important;
    letter-spacing: .5px !important;
    font-family: 'Droid Sans', sans-serif !important;
    text-decoration: none !important;
}
.CopyrightLine a:hover, .CopyrightLine a:active {
    text-decoration: underline !important;
}