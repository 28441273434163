body, html{
  overflow-x: hidden;
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Source Sans Pro', sans-serif;
}
body a{
  color: #00aa4f;
  font-size: 14px;
  font-weight: 400;
}
body a:hover{
  text-decoration: underline;
}
body a:active{
  color: #008d42;
}